import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";


class AboutSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    render() {

        return <div id="section-about" className="section section-about-us pl-4 pr-3">

            <Row>
                <Col>
                    <label className="section-title float-left text-black-100 pt-3">About</label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h6 className="text-center text-black-100 font-family-times">WELCOME TO</h6>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3 className="text-center text-large text-warning font-family-description mb-1">PONDU</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className="text-center text-large text-warning font-family-description mb-4 mt-1">
                        &#9472;&nbsp;&#9738;&nbsp;&#9472;
                    </h4>
                </Col>
            </Row>
            <Row>
                <Col md="4" ></Col>
                <Col md="4">
                    <p className="description text-black-100 text-center font-family-description" style={{fontSize:'0.9rem'}}>
                        Pondu is an App desgined to help you find Live or up coming Events, happening right in
                        your back yard. No matter where you travel to, Pondu gives you the opportunity to
                        connect with your immediate surroundings, allowing you to meet new people and
                        experiences. Keeping you connected with your community so you’ll never feel lost again.
                    </p>
                </Col>
            </Row>

        </div>;
    }

}
export default AboutSection;

