import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";


class NavigationSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    render() {

        return   <div className="section section-navigation text-center">

            <div className="pr-3 pl-3">

                <Row>
                    <Col md="2">

                    </Col>
                    <Col md="5">

                        <Row>
                            <Col>
                                <h1 className="text-center text-black-100 mt-lg-5 pt-lg-5">Built In Navigation</h1>
                            </Col>
                        </Row>

                        <Row>

                            <Col md="12">
                                <p className="text-black-100 text-center feature-text font-family-times">
                                    Navigate your way to each Event with out ever leaving the App!

                                </p>
                            </Col>
                        </Row>
                    </Col>

                    <Col md="5" className="phone-container"
                         style={{
                             backgroundImage: "url("+require('../../assets/img/landing/phone_3.png')+")"
                         }}>

                    </Col>
                </Row>
            </div>

        </div>;
    }

}
export default NavigationSection;

