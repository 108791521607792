import React from "react";


// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"

          ref={pageHeader}
        ></div>
        <div className="col-12 event-contents">
          <div className="row mt-lg-1">
                  <div className="header-event-text-container col-xl-6 col-lg-6 col-md-6 col-sm-12">

                      <div className="position-relative flex-fill">

                          <h5 className="header-event-text">Find Live <br/> Events </h5>
                      </div>

                  </div>
                  <div className="header-event-phone-img-container col-xl-6 col-lg-6 col-md-6 col-sm-12" style={{
                      backgroundImage: "url("+require('../../assets/img/landing/event_phone.png')+")"
                  }}>

                  </div>
                  <div></div>
          </div>

        </div>
          <img className="content-footer" src={require('../../assets/img/landing/city.png')} />
        <div>
             <span className="star-diamond star-color-red star-1"
             ></span>
            <span className="star-circle star-color-blue star-2"

            ></span>
            <span className="star-plus star-color-blue star-3"
            >+</span>
            <span className="star-multi star-color-green star-4"
            >+</span>
            <span className="star-multi star-color-blue star-6"
            >+</span>
            <span className="star-circle star-color-red star-5"
            ></span>
            <span className="star-diamond star-color-green star-7"
            ></span>
            <span className="star-circle star-color-red star-8"
            ></span>
            <span className="star-multi star-color-blue star-16"
            >+</span>
            <span className="star-plus star-color-green star-17"
            >+</span>
            <span className="star-circle-small star-color-green star-18"
            ></span>
            <span className="star-circle star-color-red star-19"
            ></span>
            <span className="star-plus star-color-blue star-20"
            >+</span>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
