import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";

// core components
import HelpCommonNavbar from "components/Navbars/HelpCommonNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";



function TermsPage(props) {

    let pageHeader = React.createRef();
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });


    return (
        <>
            <HelpCommonNavbar/>
            <div className="wrapper" style={{marginTop:'140px'}}>
                <div className="position-relative">
                    <h1 className="text-center" style={{marginBottom:'130px'}}>
                        Pondu Terms of Service
                    </h1>
                    <div>
                        <span className="star-diamond star-color-red star-1"
                        ></span>
                        <span className="star-circle star-color-blue star-2"

                        ></span>
                        <span className="star-plus star-color-blue star-3"
                        >+</span>
                        <span className="star-multi star-color-green star-4"
                        >+</span>
                        <span className="star-multi star-color-blue star-6"
                        >+</span>
                        <span className="star-circle star-color-red star-5"
                        ></span>
                        <span className="star-diamond star-color-green star-7"
                        ></span>
                        <span className="star-circle star-color-red star-8"
                        ></span>
                        <span className="star-multi star-color-blue star-21"
                        >+</span>
                        <span className="star-plus star-color-green star-17"
                        >+</span>
                        <span className="star-circle-small star-color-green star-18"
                        ></span>
                        <span className="star-circle star-color-red star-19"
                        ></span>
                        <span className="star-plus star-color-blue star-20"
                        >+</span>
                    </div>
                </div>
                <div className="pl-lg-5 pl-md-5 pl-sm-5 pl-5 pr-lg-5 pr-md-5 pr-sm-5 pr-5 font-family-terms-service">

                    <p>
                        Please read these Terms of Service (“Terms”) carefully. By downloading, accessing, or using the mobile applications, websites, or other products or services (“Service”) of Pondu, and our affiliates  (“Pondu”, “we” or “us”), or the Services, features, as well as any materials such as videos, images, text, graphics, etc (collectively referred to as “Content”), or functionality jointly offered with other companies through our mobile application or website, you agree to be bound by these Terms. These Terms affect your legal rights and obligations, so if you do not agree to these Terms, do not use the Services.
                    </p>
                    <br/>
                    <h3>Basic Terms</h3>
                    <li>
                        You must be at least 17 years old to use the Service
                    </li>
                    <li>
                        You are responsible for your use of the Service, for any Content you post to the Services, and for any consequences thereof
                    </li>
                    <li>
                        You should only post Content that you are comfortable sharing with others publicly
                    </li>
                    <li>
                        You may not post Content that is violent, unlawful, threatening, defamatory, hateful, racist, nude, sexually explicit, or pornographic in nature
                    </li>
                    <li>
                        You agree that you may only use the Services for yourself in compliance with these Terms, and that you are the only person using your account, unless you are authorized to do so on behalf of another organization, government, or legal entity
                    </li>
                    <li>
                        You agree to comply with all the laws, rules, and regulations applicable to your use of the Service and your Content
                    </li>
                    <li>
                        You are solely responsible for choosing strong passwords for your account and safeguarding them
                    </li>
                    <li>
                        You must not encourage or advocate harassment towards any other users of the Service, or facilitate violations of these Terms
                    </li>
                    <li>
                        We reserve the rights to add, modify, or remove certain features provided in the Services, with or without prior notice to you, at our sole discretion
                    </li>
                    <li>
                        We reserve the rights to remove, edit, or ban Content that we determine as violating these Terms
                    </li>
                    <li>
                        You agree that you are using the Service at your own risk, and that you might be exposed to Content that might be offensive or inappropriate for you
                    </li>
                    <li>
                        Pondu, its parent company Pondu LLC, or any of its partners and affiliates are not responsible for the Content posted on the Service, and shall not be in any way liable for any Content or result of the use of Content made available via the Service
                    </li>
                    <li>
                        Violations of these Terms may result in the termination of your Pondu account
                    </li>

                    <br/>
                </div>
                <div className="pl-lg-5 pl-md-5 pl-sm-5 pl-5 pr-lg-5 pr-md-5 pr-sm-5 pr-5 font-family-terms-service">
                    <h3>Privacy, Rights, and Restrictions</h3>
                    <p className="mb-1">
                        The information that you provide us with, is subject to our Privacy Policy. In order to enable your use of the Services, you may receive Service-related communications (e.g., critical changes to Service features, security notices) that you may not be able to opt-out from.
                        You retain your rights and ownership to any Content posted publicly through the Services. By using the Services, you hereby grant Pondu and its Partners a non-exclusive, sub-licensable, perpetual, irrevocable, fully-paid, royalty-free, worldwide license to use, copy, modify and distribute the Content that you post as public on or through the Service. The Content you post publicly may not be confidential or proprietary or violate or infringe on the rights of any third party, and you represent and warrant that you have all the necessary rights to grant the permissions described. Pondu and its Partners will be held neither responsible nor liable for using it.
                        Pondu grants you a personal, non-assignable, non-exclusive, non-transferrable, and non sub-licensable license to use the Services. Although not intentionally, we reserve the rights to interrupt or suspend the Services for scheduled maintenance, from time to time, without prior notice.
                        Any Content available as part of the Service that is not provided by its users is an exclusive property of Pondu and its Partners, and is protected by copyright, trademark, patent, trade secret and other laws.
                        By agreeing with these Terms, you consent that you may not modify, create derivative works based on, publish, sell, broadcast, distribute, assign, share, license, adapt, rent, or otherwise exploit any Pondu Content or Services. You may also not reverse engineer, or disassemble any source code part of the Services, or remove or modify any copyright, trademark, or other proprietary notices part of the Pondu Content or Services.
                        We reserve the right, without obligation, to remove, edit, or ban Content that we determine as violating the Terms, and/or to terminate any account that violates the Terms, without prior notice.
                    </p>
                </div>
                <div className="pl-lg-5 pl-md-5 pl-sm-5 pl-5 pr-lg-5 pr-md-5 pr-sm-5 pr-5 font-family-terms-service">
                    <h3>Disclaimers of Warranties</h3>
                    <p className="mb-1">
                        The Pondu Services, including, without limitation, any Pondu Content, is provided “as is”. Pondu, Pondu LLC, or any of its Partners hereby disclaim all warranties of any kind, expressed or implied, including, without limitation: i) the Pondu Services; ii) the Pondu Content; iii) any user generated Content; iv) any loss, damage, injury, or other harm that results from your access to the Service or Content; v) all implied warranties and conditions of merchantability, fitness for a particular purpose, title, and non-infringement.
                        You acknowledge that your use of the Service is at your own risk, and that you might be exposed to Content that might be offensive or inappropriate for you. We do not make any representation or warranties that the Content or information available as part of the Service is accurate, complete, or useful. In addition, we do not warrant that the Service will be available on an uninterrupted, error-free basis.
                        Any links to third party websites or services provided as part of the Service are not the responsibility of Pondu, Pondu LLC, or its Partners, and you acknowledge and agree that your usage of them is done at your own risk.
                    </p>
                </div>
                <div className="pl-lg-5 pl-md-5 pl-sm-5 pl-5 pr-lg-5 pr-md-5 pr-sm-5 pr-5 font-family-terms-service">
                    <h3>Limitation of Liability</h3>
                    <p className="mb-1">
                        Under no circumstances shall Pondu, Pondu LLC, or any of its Partners be liable for any loss, damage, injury, or other harm, whether directly or indirectly, that results from your access to or inability to access or use of the Service or the Content.
                        In no event shall the total liability of Pondu, Pondu LLC or its Partners to you for all damages, exceed One Hundred U.S. Dollars ($100.00).
                    </p>
                </div>
                <div className="pl-lg-5 pl-md-5 pl-sm-5 pl-5 pr-lg-5 pr-md-5 pr-sm-5 pr-5 font-family-terms-service">
                    <h3>General Terms</h3>
                    <li>
                        if we suspect you are violating these Terms, we may suspend or terminate your account, or restrict access to all or part of the Services
                    </li>
                    <li>
                        these Terms will be in effect until you choose to delete your account. If you decide to do so, please contact us
                    </li>
                    <li>
                        any failure by any party to enforce at any time any right or provision of these Terms will not be considered a waiver of such right or provision
                    </li>
                    <li>
                        if any provision of these Terms is declared invalid, unenforceable, unlawful, illegal, or void, the remainder of these Terms will be valid and enforceable to the extent permitted by law. We agree to use our best efforts to limit or eliminate the invalid provision to the minimum extent necessary
                    </li>
                    <li>
                        these Terms are governed by and construed in accordance with the laws of the State of Florida, without regard to or application of its conflict of law of your state, country, or residence. You agree to dispute any action at law, claim, or proceeding related to the Services, Content, or these Terms, exclusively in the federal or state courts located in Orange County, Florida, United States
                    </li>
                    <li>
                        these Terms and our Privacy Policy, constitute the entire agreement between you and Pondu, Pondu LLC, and its Partners, and supersede any other communications with respect to the Services
                    </li>
                    <li>
                        we reserve the right to revise these Terms from time to time. Even though we will provide forms of notice as appropriate under the circumstances, please review the Terms directly periodically. Your continued use of Pondu or the Service after any modification to these Terms will constitute your acceptance of such modification.
                    </li>
                    <h4>
                        Effective: January 1st, 2021
                    </h4>
                </div>


                <DefaultFooter/>
            </div>



        </>
    );
}

export default TermsPage;
