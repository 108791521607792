/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container ,Nav,NavItem} from "reactstrap";
// core components

function DefaultFooter() {
  return (
    <>
        <div className="section section-outsite-link pt-4">
            <Container>
               <div className="float-left mt-2">
                 <div>
                   <a className="font-family-times mr-2" href="/terms" color="white">
                       TERMS
                   </a>
                    <a className="font-family-times mr-2" href="/privacy">
                        PRIVACY
                    </a>
                    <a className="font-family-times mr-2" href={require('../../assets/pondu.zip')} download="pondu.zip">
                        PRESS
                    </a>
                    <a className="font-family-times mr-2" href="/help">
                        HELP
                    </a>
                  </div>
                  <div className="address font-family-times" style={{color:"#AAA"}}>
                    (407) 840-0688 Orlando, Fl, 32808
                  </div>
               </div>
                <div className="float-right">
                  <a className="btn-social fab fa-twitter-square" href="https://mobile.twitter.com/pondusocial" target="_blank"
                    style={{fontSize:'34px'}}></a>

                  <a className="btn-social fab fa-facebook-square"  style={{fontSize:'34px'}}
                  href="https://www.facebook.com/Pondu-131700460900193/?notif_id=1616393655159985&notif_t=page_fan&ref=notif" target="_blank"
                  ></a>

                  <a className="btn-social fab fa-instagram"  style={{fontSize:'34px'}}
                  href="https://instagram.com/ponduapp?igshid=1aimjvue641og" target="_blank"
                  ></a>
                </div>
            </Container>
        </div>
      <footer className="footer footer-default pt-2 pb-2 bg-black">
        <Container>
          <nav>
              <div className="copyright text-white font-family-description" id="copyright">
                  @Copyright 2021 Pondu. All right reserved.
              </div>
          </nav>

        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
