import request from "./request";
import  apiURL  from "./apiURL";
import  {BASE_URL} from "../config";

export const sendMail = (data) => {
    return request(BASE_URL, {
        url: apiURL.mail_url,
        method: "POST",
        data:data
    });
};

export const getPurchase = () => {
    return request(BASE_URL, {
        url: apiURL.purchase_url,
        method: "POST"
    })
}
