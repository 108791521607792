import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";

import {getPurchase} from "../../Services";
// core components
import ShopNavbar from "components/Navbars/ShopNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function PurcahsedPage(props) {
    let pageHeader = React.createRef();

    React.useEffect(() => {      
          
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <ShopNavbar/>
            <div className="wrapper" style={{paddingTop: "140px", textAlign: "center"}}>
                <div className="container" style={{minHeight: "70vh", width: "50%"}}>
                    <img src={require("assets/img/logo.png")} alt="logo" />
                    <div style={{color: "rgb(58 239 62)"}}>
                        <h1 className="font-family-times">Thank You!</h1>
                    </div>
                    <div>
                        <h4 className="font-family-times">You purchased a tickets</h4>
                        <h3 className="font-family-times">Please check your mailbox</h3>
                    </div>
                </div>
                <DefaultFooter/>
            </div>
        </>
    );
}

export default PurcahsedPage;
