import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";

// core components
import HelpCommonNavbar from "components/Navbars/HelpCommonNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";



function TermsPage(props) {

    let pageHeader = React.createRef();
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });


    return (
        <>
            <HelpCommonNavbar/>
            <div className="wrapper" style={{marginTop:'140px'}}>
                <div className="position-relative">
                <h1 className="text-center" style={{marginBottom:'30px'}}>
                    Help Center Explain
                </h1>
                    <div>
                        <span className="star-diamond star-color-red star-1"
                        ></span>
                        <span className="star-circle star-color-blue star-2"

                        ></span>
                        <span className="star-plus star-color-blue star-3"
                        >+</span>
                        <span className="star-multi star-color-green star-4"
                        >+</span>
                        <span className="star-multi star-color-blue star-6"
                        >+</span>
                        <span className="star-circle star-color-red star-5"
                        ></span>
                        <span className="star-diamond star-color-green star-7"
                        ></span>
                        <span className="star-circle star-color-red star-8"
                        ></span>
                        <span className="star-multi star-color-blue star-21"
                        >+</span>
                        <span className="star-plus star-color-green star-17"
                        >+</span>
                        <span className="star-circle-small star-color-green star-18"
                        ></span>
                        <span className="star-circle star-color-red star-19"
                        ></span>
                        <span className="star-plus star-color-blue star-20"
                        >+</span>
                    </div>
                </div>
                <div className="mt-5 pl-lg-5 pl-md-5 pl-sm-5 pl-5 pr-lg-5 pr-md-5 pr-sm-5 pr-5 font-family-terms-service">

                    <h3 className="mt-1">What is Pondu?</h3>
                    <p>
                        Pondu is a Social Event Mapping App that makes it easier to find new friends on your college campus. Creating a fast and exciting way to discover the Social events around you. Giving you the ability to step out of your comfort zone, and push the limits of social media.
                    </p>
                    <h3 className="mt-1">What is Pondu’s Mission?</h3>
                    <p>
                        Here at Pondu our mission is to bring people together. By making it easier to discover the events around ones community. In hopes of creating stronger, positive, life lasting relationships.
                    </p>
                    <h3 className="mt-1">How Much Does Pondu Cost?</h3>
                    <p>
                        Pondu is free to download. However there are some updates coming that will allow in-app purchases.
                    </p>
                    <h3 className="mt-1">What problem is Pondu Solving?</h3>
                    <p>
                        The problem Pondu is solving is taking big communities such as college campuses and making them feel small again. The type of small when no one ever feels left out, always giving you the opportunity to experience something amazing.  Such as creating adventurous memories, strong connections, and life long friendships.
                    </p>
                    <h3 className="mt-1">Event Detail</h3>
                    <p>
                        The Event Detail page allows you to manage any party or event you may be hosting. There is an edit button that allows you to change any information about your event and a trash button that allows you to permanently delete your event. Only the user that created the event can change or delete the event. All other users will only be able to see the basic information about the event.
                    </p>
                    <h3 className="mt-1">
                        Settings
                    </h3>
                    <p>
                        In the settings menu you can edit profile information, go over the Privacy Policy and Terms of Service. Change the way your account operates, like who can contact you or see your event.
                    </p>
                    <h3 className="mt-1">
                        Location
                    </h3>
                    <p>
                        The Location feature is a big part of the app, because it allows you the user to find the events happening around you. The downfall of this great feature is that it kills battery life tremendously.
                    </p>
                </div>
                <div className="mb-5 pl-lg-5 pl-md-5 pl-sm-5 pl-5 pr-lg-5 pr-md-5 pr-sm-5 pr-5 font-family-terms-service">
                    <h3>How to Sign-Up</h3>
                    <p>Creating an Account and Username
                        To get started, download the Pondu app from the iTunes App Store for iOS. We currently do not offer the Pondu app on the Google Play Store for Android. Follow these steps below to sign up for a new account once you have downloaded the app.
                    </p>
                    <ul>1.	On the Welcome screen, tap SIGN UP.</ul>
                    <ul>2.	Tap the field that says 'Email Address' and enter a valid email address. It's important to have a valid email address associated with your account. If you forget your password, your email address can be used to reset it. Tap the field that says 'Full Name' and enter the name you would like to associate with your account.</ul>
                    <ul>3.	Tap the field that says 'Full Name' and enter the name you would like to associate with your account.</ul>
                    <ul>4.	Choose your username. Your username is how you will be recognized on Pondu. We encourage you to be creative with this one!</ul>
                    <ul>5.	Tap the field that says PASSWORD and enter a secure password. Your password must be at least 8 characters.</ul>
                    <ul>6.	Tap Verify Password. Re-enter to your PASSWORD to make sure it matched up with the original entry.</ul>
                    <ul>7.	Pick your School. Choosing a home school allows you to always be connected to your campus no matter your location.</ul>
                    <ul>8.	Verify your phone number. This step is optional, and you can verify your phone number at any time. If you ever forget your password, we can send a password reset text to your verified phone number.</ul>
                    <ul>9.	And finally, review the Terms of Service and Privacy Policy. If you choose to accept them, then you are free to make an account.</ul>

                    <h3>How to Create a Post</h3>

                    <ul>1.	After Logging into the app and being prompted to the map, tap on the little white navigation button found at the bottom of the screen.</ul>
                    <ul>2.	Once the button has expanded you will see 3 icons. One for the Map (the top icon), one for Settings ( the left icon) and one for Posting ( the right icon). Click on the posting icon.</ul>
                    <ul>3.	This will bring you to the Event Title page. Here you will give your event a name. After doing so tap the next arrow found at in the middle of the screen.</ul>
                    <ul>4.  Now you will be asked what type of event will you be hosting? A regular event, which covers a broad field of social atmospheres or a party which includes dancing and music. After choosing the Event Type, tap the next arrow.</ul>
                    <ul>5.	On this next page you will set the date and times for your event. Once this is done you may proceed to the Location page.</ul>
                    <ul>6.	To set the location of your event you can either manually type the address or if you are currently at the location you may tap the ‘my location’ button to get your current address. Then move on to the final page.</ul>
                    <ul>7.	On this last page you will be able to review all your information while also being asked to write a quick description about your event. After all this is done you may post your event to the Map.</ul>



                </div>

                <DefaultFooter/>
            </div>



        </>
    );
}

export default TermsPage;
