import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";


class DownloadSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    render() {

        return <div className="section section-download text-center pt-0 pb-3">
            <Container className="mt-lg-3">
                <Row>
                    <Col className="text-left ml-auto mr-auto" lg="6" md="6">

                        <h4 className="font-family-times pt-lg-3 text-md-left font-weight-bold">Download Our App now!</h4>
                        <p className="font-family-description">Available on the Ios App Store. Android version coming soon!</p>
                        <a className="btn btn-black btn-download" href="https://apps.apple.com/us/app/pondu/id1516488990" target="_blank">
                            <i className="fab fa-apple"></i>
                            <span>
                                Download on the
                                <br/>
                                <i>App Store</i>
                            </span>
                        </a>
                    </Col>
                    <Col className="text-left ml-auto mr-auto" lg="6" md="6">
                        <img className=""
                             src={require('../../assets/img/landing/us.png')}/>
                    </Col>
                </Row>
            </Container>
        </div>;
    }

}
export default DownloadSection;

