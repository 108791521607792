import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";
import {FirestoreCollection,withFirestore} from 'react-firestore';
import * as CONFIG from "../../config";
// core components


class EventSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalLive: false,
            eventVideoPlaying: false,
            events:[],
            eventSize:0,
            curIndex:-1,
            loadingEvent:false,
            animationClassName:'',
            filtered: [],
            searching:false,
            previewVideo:false,
            previewVideoUrl:""
        }
    }
    setModalLive = (val) => {
        this.setState({modalLive: val});
    }
    setEventVideoPlaying = (val) => {
        this.setState({eventVideoPlaying: val});
    }
    eventVideoPlayAndPause = () => {
        //return;
        if (this.state.eventVideoPlaying) {
            var video = document.querySelector(".event-video-preview");
            video.pause();
        }
        else {
            var video = document.querySelector(".event-video-preview");
            video.play();
            //var url = this.state.story.data().url;

            //console.log(url,this.state.story.data().url);
            //this.setState({previewVideo:true,previewVideoUrl:url});
        }
        this.setEventVideoPlaying(!this.state.eventVideoPlaying);
    }
    componentDidMount() {
        const { firestore } = this.props;
        firestore.collection('events').orderBy("timeStamp","asc").onSnapshot(snapshot => {

            if(snapshot.size == 0){
                this.setState({events:[],story:null,curIndex:-1});
                return;
            }
            this.setState({events:snapshot.docs,eventSize:snapshot.size});
            if(this.state.curIndex >= snapshot.size || this.state.curIndex==-1){
                var self = this;
                this.onEventChange(0,function(story){
                    self.setState({story:story,loadingEvent:false,curIndex:0});
                });


            }
        });
        //firestore.doc("stories").filter({event:'ssd87f8s7f8s7f'})
    }
    onEventChange=(index,callback)=> {
        return;//
        const { firestore } = this.props;
        let event_id = this.state.events[index].id;
        console.log(event_id,"change event id", event_id);

        this.setState({loadingEvent:true},function(){
               firestore.collection("stories").where("event","==",event_id)
                .onSnapshot(snapshot=>{
                    callback(snapshot.docs[0] || null);
                });
        });
    }
    prevEvent = ()=>{
        return;
        var index =this.state.curIndex;
        if(index==this.state.eventSize-1) return;
        var self = this;

        this.setState({animationClassName:""},function(){
            self.onEventChange(index+1,function(story){
                self.setState({story:story , curIndex:index+1 ,loadingEvent:false,animationClassName :"pickPrevAnimation"});
            });
        });

    }
    nextEvent = ()=> {
        return;
        var index = this.state.curIndex;
        if (index == 0) return;
        var self = this;

        this.setState({animationClassName:""},function(){
            self.onEventChange(index-1,function(story){
                self.setState({story:story , curIndex:index-1 ,loadingEvent:false,animationClassName :"pickNextAnimation"});
            });
        });

    }
    searchEvent= e=>{
           var searchValue = e.target.value;
           var filtered = [];
           this.state.events.map(event=>{
               var data = event.data();
               if(data.title.indexOf(searchValue) > -1)
                 filtered.push(data);
           });
           console.log(filtered);
           this.setState({filtered:filtered,searching:true});
    }
    selectEvent = (eventId)=>{
        var self = this;
        this.state.events.map((event,index)=>{
            var data = event.data();
            if(data.event ==  eventId){
                self.setState({animationClassName:""},function(){
                    self.onEventChange(index,function(story){
                        self.setState({story:story , curIndex:index ,loadingEvent:false,animationClassName :"pickNextAnimation"});
                    });
                });
                return;
            }
        });
    }

    render() {
        const {modalLive, eventVideoPlaying} = this.state;
        const { story } = this.state;
        console.log(story)
        const storyData = story ? story.data() : {};
        console.log(storyData,story);
        return <>

            <div id="section-events"
                 className="section section-events"

            >
                <div className="event-video-preview-container">
                    { storyData.image?
                        (<img className={"event-video-preview " + this.state.animationClassName}
                                  src={storyData.image} />)
                        :(
                                      this.state.loadingEvent?
                                          (<>

                                              </>
                                          ):
                                          (<div className="position-center">

                                          </div>)
                        )
                    }
                    <video    className="event-video-preview" src={require('../../assets/video/event_preview.mp4')}
                    poster={require("../../assets/img/landing/event_video_preview.png")}
                    >

                    </video>

                </div>
                <div
                    className="pl-3 pt-3 position-absolute event-container"

                >
                    <h3
                        className="section-title float-left text-white"> Events </h3>
                    <i
                        className="now-ui-icons ui-1_zoom-bold icon-before-search">
                    </i>
                    <a className="btn-link event-info-btn float-right pr-3 pt-1 text-white"
                       onClick={() => this.setModalLive(true)}>
                        <i className="now-ui-icons travel_info"></i>
                    </a>
                    <a className={ "position-left-middle btn-prev-event " + ((this.state.loadingEvent && this.state.eventSize == this.state.curIndex+1 )?"move-disabled":"")} onClick={this.prevEvent}>
                        <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a className={ "position-right-middle btn-next-event " + ((this.state.loadingEvent && this.state.curIndex==0)?"move-disabled":"")} onClick={this.nextEvent}>
                        <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                    <>
                        {this.state.loadingEvent?(
                                <a
                                    className="position-center btn-event-play btn-outline- btn-circle"
                                    >
                                    <i className="now-ui-icons arrows-1_refresh-69 animate-rotate"></i>


                                </a>
                        ):
                        (<a
                        className="position-center btn-event-play btn-outline- btn-circle"
                        onClick={this.eventVideoPlayAndPause}>
                        {
                            !eventVideoPlaying ? (
                                    <i className="fa fa-play"></i>) :
                                (<i className="fa fa-pause"></i>)
                        }
                        </a>)
                        }
                       </>
                    <input className="event-search-input" placeholder="Search" onChange={(e)=>{this.searchEvent(e)}}
                    onBlur={()=>{setTimeout(()=>{this.setState({searching:false})},200)}}>
                    </input>
                    <div className={"event-search-result " +  (this.state.searching?"":"hidden")} >
                        {
                            this.state.filtered.map(data => (
                                <li key={data.event} onClick={()=>{this.selectEvent(data.event)}}>{data.title}</li>
                            ))
                        }
                    </div>

                </div>
                <Modal className="position-center" toggle={() => this.setModalLive(false)} isOpen={modalLive}>
                    <div className="modal-header pt-2 text-center">
                        <h5 className="modal-title text-center" id="exampleModalLiveLabel">
                            Details
                        </h5>
                        {/* <button
                                aria-label="Close"
                                className="close"
                                type="button"
                                onClick={() => setModalLive(false)}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>*/}
                    </div>
                    <div className="modal-body">
                        <p>Woohoo, you're reading this text in a modal!</p>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            type="button"
                            onClick={() => this.setModalLive(false)}
                        >
                            Close
                        </Button>
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => this.setModalLive(false)}
                        >
                            Save changes
                        </Button>
                    </div>
                </Modal>
                <Modal className="position-center" toggle={() => this.setState({previewVideo:false})} isOpen={this.state.previewVideo}>

                </Modal>
            </div>
        </>;


    }

}
export default withFirestore(EventSection);

