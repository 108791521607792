import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Alert,
    Modal
} from "reactstrap";


import axios from "axios";



import {sendMail} from "../../Services";

class ContactSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name:"",
            email:"",
            message:"",
            invalidName:" ",
            invalidEmail:" ",
            invalidMessage:" ",
            visible: false
        }
        this.onDismiss = this.onDismiss.bind(this);
    }
    onDismiss(){
        this.setState({visible: !this.state.visible});
    }
    nameChange = (e)=>{
        var invalidName = e.target.value?"":"Please input name!";
        this.setState({name:e.target.value,invalidName:invalidName})
    }
    emailChange = (e)=>{
        var invalidEmail;
        if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(e.target.value)){
            invalidEmail="Please input valid email!";
        }
        else invalidEmail="";
        this.setState({email:e.target.value,invalidEmail:invalidEmail});
    }
    messageChange = (e)=>{
        var invalidMessage = e.target.value?"":"Please input message!";
        this.setState({message:e.target.value,invalidMessage:invalidMessage});

    }
    sendMessage = ()=>{
        var invalidName = this.state.name?"":"Please input name!";
        var invalidEmail;
        if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email))
            invalidEmail="Please input valid email!";
        else
            invalidEmail="";
        var invalidMessage = this.state.message?"":"Please input message!";

        if(invalidEmail || invalidName || invalidMessage){
            this.setState({invalidName:invalidName,invalidEmail:invalidEmail,invalidMessage:invalidMessage});
            return;
        }
        var data = {
           name:this.state.name,
           email:this.state.email,
           message:this.state.message
        }
        sendMail(data).then(data=>{
           if(!data.error){
               this.onDismiss();
           }
        }).catch(e=>{

        });
    }
    render() {

        return <div id="section-us" className="section section-contact-us text-center pt-0 pb-3 border-bottom"
                 >
                <Row>
                    <Col>
                        <label className="section-title float-left text-black-100 pt-3 pl-3">Contact Us</label>
                    </Col>
                </Row>
                <Container className="mt-lg-3">
                    <Row>
                        <Col className="text-left ml-auto mr-auto" lg="6" md="6">
                            <div className="contact-input-container">
                                <label>
                                    Name
                                </label>
                                <Row>
                                    <Col md="8">
                                        <input  type="text" className="userName" value={this.state.name} onChange={this.nameChange}>

                                        </input>
                                    </Col>
                                    <Col md="4">
                                        <label className="text-danger text-invalid">{this.state.invalidName}</label>
                                    </Col>
                                </Row>
                            </div>
                            <div className="contact-input-container">
                                <label>
                                    Email
                                </label>
                                <Row>
                                    <Col md="8">
                                        <input type="email" className="userEmail" placeholder="name@example.com"
                                                value={this.state.email} onChange={this.emailChange}>

                                        </input>
                                    </Col>
                                    <Col md="4">
                                        <label className="text-danger text-invalid">{this.state.invalidEmail}</label>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                        <Col className="text-left ml-auto mr-auto" lg="6" md="6">
                            <div className="contact-textarea-container">
                                <label>
                                    Message
                                </label>
                                <Row>
                                    <Col md="9">
                                            <textarea className="contactMessage " value={this.state.message} onChange={this.messageChange}>

                                            </textarea>
                                        <a className="btn-send" onClick={this.sendMessage}>
                                            <i className="fa fa-paper-plane"></i>
                                        </a>
                                    </Col>
                                    <Col md="3">
                                        <label className="text-danger text-invalid">{this.state.invalidMessage}</label>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Alert color="info" isOpen={this.state.visible}>
                        <div className="container">

                                Thank you  for contact.
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={this.onDismiss}
                                >
                                <span aria-hidden="true">
                                  <i className="now-ui-icons ui-1_simple-remove"></i>
                                </span>
                               </button>
                        </div>
                    </Alert>
                </Container>
            <div>
             <span className="star-diamond star-color-blue star-9"
             ></span>
                <span className="star-circle star-color-blue star-10"

                ></span>
                <span className="star-plus star-color-blue star-11"
                >+</span>
                <span className="star-multi star-color-blue star-12"
                >+</span>
                <span className="star-circle star-color-red star-13"
                ></span>
                <span className="star-circle star-color-red star-14"
                ></span>
                <span className="star-circle star-color-red star-15"
                ></span>
            </div>
            </div>;
    }

}
export default ContactSection;

