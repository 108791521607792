import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";


class PaidSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            eventVideoPlaying:false
        }
    }
    componentDidMount =()=>{

    }
    eventVideoPlayAndPause=()=>{

            if (this.state.eventVideoPlaying) {
                var video = document.querySelector(".paid-video-preview");
                video.pause();
            }
            else {
                var video = document.querySelector(".paid-video-preview");
                video.play();

            }
            this.setState({eventVideoPlaying: !this.state.eventVideoPlaying});

    }
    render() {
        const {eventVideoPlaying} = this.state;
        return    <div id="section-paid" className="section section-paid pl-3 pr-3 pt-0">

            <Row>
                <Col>
                    <label className="section-title float-left text-black-100 pt-3">Get Paid</label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3 className="text-center text-black-100 mb-2">Make Money On Pondu</h3>
                </Col>
            </Row>

            <Row>
                <Col md="4"></Col>
                <Col md="4">
                    <p className="description text-black-100 text-center font-family-description">
                        Create Paid Events and Sell Tickets. Keep track of all your ticket sales right from the app!
                    </p>
                </Col>
            </Row>
            <div className="pl-lg-5 pr-lg-5">
                <Row>
                    <Col md="2"></Col>
                    <Col md="8">
                        <video className="paid-video-preview" onEnded={(e)=>{this.setState({eventVideoPlaying:false})}}
                             src={require('../../assets/video/paid.mp4')}
                              style={{
                                width:'100%'
                              }}
                        >

                        </video>

                        <a className="position-center btn-welcome-play btn-outline- btn-circle"
                           onClick={this.eventVideoPlayAndPause}>
                            {
                                !eventVideoPlaying ? (
                                        <i className="fa fa-play"></i>) :
                                    (<i className="fa fa-pause"></i>)
                            }
                            </a>
                    </Col>
                    <Col md="2"></Col>
                </Row>
            </div>

        </div>;
    }

}
export default PaidSection;

