import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";

// core components
import ShopNavbar from "components/Navbars/ShopNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function ShopPage(props) {

    let pageHeader = React.createRef();
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <ShopNavbar/>
            <div className="wrapper">
                <iframe src='https://funnel.ponduapp.com/tickets47728940' width='100%' frameBorder='0' style={{height: "1600px"}} scrolling="no"></iframe>
                <DefaultFooter/>
            </div>
        </>
    );
}

export default ShopPage;
