import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";

// core components
import CommonNavbar from "components/Navbars/CommonNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import EventSection from "./events.js";
import ContactSection from "./contact.js";
import FeactureSection from "./feature.js";
import AboutSection from "./about.js";
import NavigationSection from "./navigation.js";
import PaidSection from "./paid.js";
import DownloadSection from "./download.js";


class LandingPage extends React.Component{
    constructor(props){
        super(props);

    }
    componentDidMount(){
        this.pageHeader = React.createRef();
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        var  curHash = document.location.hash;
        console.log(curHash)
        document.location.hash = "";
        if(curHash.length > 1){
            curHash = curHash.substr(1);
            var section = document.getElementById("section-"+curHash);
            console.log(section)
            if(section){
                section.scrollIntoView();
                window.scrollBy(0,-60);
            }
        }
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };

    }

    render()
    {
        return (
            <>
                <CommonNavbar/>
                <div className="wrapper">
                    <LandingPageHeader/>
                    <EventSection firebase={this.props.firebase}/>
                    <AboutSection/>
                    <FeactureSection/>
                    <div className="section section-stories  text-center">

                        <div className="mr-3 ml-3">

                            <Row>
                                <Col md="6" className="story-img-left">
                                    <img className="story-phone-img"
                                         src={require('../../assets/img/landing/IMG_8152.JPG')}/>
                                </Col>
                                <Col md="6" className="pt-5 pr-lg-5 pl-lg-5 story-text">

                                    <Row>
                                        <Col>
                                            <h1 className="text-center text-black-100">Stories</h1>
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col md="12">
                                            <p className="text-black-100 text-center feature-text font-family-times">
                                                With our Stories Feature you get an inside look of every Event without
                                                even leaving the comfort of you home. Helping you decide to attent
                                                before making any commitment!

                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6" className="story-img-right">
                                    <img className="story-phone-img"
                                         src={require('../../assets/img/landing/IMG_8152.JPG')}/>
                                </Col>

                            </Row>
                        </div>

                    </div>
                    <NavigationSection/>
                    <div className="section section-connected  text-center">

                        <div className="mr-3 ml-3">

                            <Row>
                                <Col md="6">
                                    <img className="connected-phone-img"
                                         src={require('../../assets/img/landing/phone_4.png')}/>
                                </Col>
                                <Col md="6" className="pr-lg-5 pl-lg-5 mt-3 mt-lg-5 pt-lg-5">

                                    <Row>
                                        <Col>
                                            <h1 className="text-center text-black-100">Stay Connected</h1>
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col md="12">
                                            <p className="text-black-100 text-center feature-text font-family-times">
                                                Connect with friends and Organizers with our
                                                Messaging feature. Allowing you to always stay in the loop

                                            </p>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </div>

                    </div>
                    <PaidSection/>
                    <ContactSection/>
                    <DownloadSection/>
                    <DefaultFooter/>
                </div>


            </>
        );
    }
}

export default LandingPage;
