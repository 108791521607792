/*

=========================================================
* Now UI Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/fontawesome/css/all.min.css";
import "assets/demo/demo.css";
import "assets/demo/custom.css";
import "assets/demo/nucleo-icons-page-styles.css";

// pages for this kit

import LandingPage from "views/landing/LandingPage.js";
import TermsPage from "views/helps/TermsPage.js";
import ShopPage from "views/shop/shop.js";
import PurchasedPage from "views/shop/purchased.js";
import PrivacyPage from "views/helps/PrivacyPage.js";
import HelpPage from "views/helps/HelpPage.js";
import firebase from '@firebase/app';
import '@firebase/firestore';
import {FirestoreProvider} from 'react-firestore';
import VideoView from "views/VideoView";

var firebaseConfig = {
    apiKey: "AIzaSyDr3NEiHq_kHMwRyO8ZXxpRVFZuiSGwNCk",
    authDomain: "pondu-f3a92.firebaseapp.com",
    databaseURL: "https://pondu-f3a92.firebaseio.com",
    projectId: "pondu-f3a92",
    storageBucket: "pondu-f3a92.appspot.com",
    messagingSenderId: "1032769834435",
    appId: "1:1032769834435:web:a2297b42d209dd0cee0176",
    measurementId: "G-3E57TXY5BR"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
    <FirestoreProvider firebase={firebase}>
       <BrowserRouter>
            <Switch>
                <Switch>
                    <Route
                        path="/index"
                        render={props => <LandingPage {...props} firebase={firebase} />}
                    />
                    <Route path="/tickets"
                              render={props => <ShopPage {...props}/> }
                    />
                    <Route path="/purchased"
                                render={props => <PurchasedPage {...props}/>} 
                    />
                    <Route path="/terms"
                              render={props => <TermsPage {...props}/> }
                    />
                    <Route path="/privacy"
                                  render={props => <PrivacyPage {...props}/>}
                    />
                    <Route path="/help"
                                  render={props => <HelpPage {...props}/>}
                    />
                    <Route path="/Mst6cI212s" 
                                  render={props => <VideoView {...props} firebase={firebase} />} 
                    />
                    <Redirect to="/index"/>
                    <Redirect from="/" to="/index"/>
                </Switch>
            </Switch>
       </BrowserRouter> </FirestoreProvider>, document.getElementById("root")
);
