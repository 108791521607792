import React, {useEffect, useState} from "react";
import queryString from 'query-string';

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";
import {FirestoreCollection,withFirestore} from 'react-firestore';
// core components
import CommonNavbar from "components/Navbars/CommonNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import DownloadSection from "./landing/download.js";
function VideoView(props) {

    const [thumbnail, setThumbnail] = useState("https://firebasestorage.googleapis.com/v0/b/pondu-f3a92.appspot.com/o/stories%2FkCTRs47DUXSfZg1osj5z7ie7HkS2%2Fvideos%2F10daef2b-9d8e-4613-b7e2-2d35011ddba3.mp4?alt=media&token=00585dee-c7e2-4028-96ee-8803571bb741");
    const [videoUrl, setVideoUrl] = useState("https://firebasestorage.googleapis.com/v0/b/pondu-f3a92.appspot.com/o/stories%2FkCTRs47DUXSfZg1osj5z7ie7HkS2%2FThumbnail_Files%2F14ea83a2-879e-4bf4-b0a8-64a6b104f04a.jpeg?alt=media&token=8ccffda0-f08c-411c-a050-2d1a96660a7d");
    const [eventVideoPlaying, setEventVideoPlaying] = useState(false)
    useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const eventVideoPlayAndPause = () => {
        if (eventVideoPlaying) {
            var video = document.querySelector(".paid-video-preview");
            video.pause();
        }
        else {
            var video = document.querySelector(".paid-video-preview");
            video.play();
        }
        setEventVideoPlaying(!eventVideoPlaying);
    }

    useEffect(()=>{
        const { firestore } = props;
        let url = props.location.search;
        let params = queryString.parse(url);
        const {event} = params;

        firestore.collection('stories').where('event', '==', event).get().then(querySnapshot => {
            if (!querySnapshot.empty) {
                const snapshot = querySnapshot.docs[0];
                const data = snapshot.data();
                console.log(data)
                setThumbnail(data.image);
                setVideoUrl(data.url);
                // data.url, data.image
                // "https://firebasestorage.googleapis.com/v0/b/pondu-f3a92.appspot.com/o/stories%2FkCTRs47DUXSfZg1osj5z7ie7HkS2%2Fvideos%2F10daef2b-9d8e-4613-b7e2-2d35011ddba3.mp4?alt=media&token=00585dee-c7e2-4028-96ee-8803571bb741"
                // "https://firebasestorage.googleapis.com/v0/b/pondu-f3a92.appspot.com/o/stories%2FkCTRs47DUXSfZg1osj5z7ie7HkS2%2FThumbnail_Files%2F14ea83a2-879e-4bf4-b0a8-64a6b104f04a.jpeg?alt=media&token=8ccffda0-f08c-411c-a050-2d1a96660a7d"
            } else {

            }            
        });
    });

    return (
        <>
            <CommonNavbar/>
            <div className="wrapper" style={{marginTop:'70px', textAlign: 'center'}}>
                <div className="event-video">                   
                    <video className="paid-video-preview" onEnded={(e)=>{setEventVideoPlaying(false)}}
                            src={videoUrl}
                    >
                    </video>

                    <a className="position-center btn-welcome-play btn-outline- btn-circle"
                        onClick={eventVideoPlayAndPause}>
                        {
                            !eventVideoPlaying ? (
                                    <i className="fa fa-play"></i>) :
                                (<i className="fa fa-pause"></i>)
                        }
                        </a>
                </div>
                <DownloadSection/>
                <DefaultFooter/>
            </div>
        </>
    );
}

export default withFirestore(VideoView);
