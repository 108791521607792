import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";
import * as CONFIG from "../../config";

function CommonNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top"} color="info" expand="lg">
        <div className="position-relative col-12" >

          <div className="navbar-translate pull-left">
            <NavbarBrand
              href={CONFIG.BASE_URL+"/index"}
              id="navbar-brand"
            >
                <img className="page-logo-img" src={require('../../assets/img/logo.png')} />
                Pondu
            </NavbarBrand>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end pull-right"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar className="mr-lg-5">
              <NavItem>
                <NavLink to="/index#events" tag={Link}
                onClick={e=>{
                    e.preventDefault();
                    window.scrollTo(
                      0,
                      document.getElementById("section-events").offsetTop - 60
                    );
                    
                    document.documentElement.classList.remove("nav-open");
                    setCollapseOpen(false);
                }}
                >
                  Events
                </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink to="/index#about" tag={Link}
                           onClick={e=>{
                               e.preventDefault();
                               window.scrollTo(
                                  0,
                                  document.getElementById("section-about").offsetTop - 60
                                );
                                
                               document.documentElement.classList.remove("nav-open");
                               setCollapseOpen(false);
                           }}
                  >
                      About
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink to="/index#features" tag={Link}
                           onClick={e=>{
                               e.preventDefault();
                               window.scrollTo(
                                  0,
                                  document.getElementById("section-features").offsetTop - 60
                                );
                                
                               document.documentElement.classList.remove("nav-open");
                               setCollapseOpen(false);
                           }}
                  >
                      Features
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink to="/index#paid" tag={Link}
                           onClick={e=>{
                               e.preventDefault();
                               window.scrollTo(
                                  0,
                                  document.getElementById("section-paid").offsetTop - 60
                                );
                               document.documentElement.classList.remove("nav-open");
                               setCollapseOpen(false);
                           }}

                  >
                      Get Paid
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink to="/index#us" tag={Link}
                           onClick={e=>{
                               e.preventDefault();
                               window.scrollTo(
                                0,
                                document.getElementById("section-us").offsetTop - 60
                              );
                               document.documentElement.classList.remove("nav-open");
                               setCollapseOpen(false);
                           }}
                  >
                      Contact US
                  </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/tickets" tag={Link}>
                  Tickets
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </>
  );
}

export default CommonNavbar;
