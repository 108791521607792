import React from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";


class FeatureSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    render() {

        return <div id="section-features" className="section section-features text-center pl-4">
            <Row>
                <Col>
                    <label className="section-title float-left text-black-100 pt-3">Feature</label>
                </Col>
            </Row>
            <div className="mr-3 ml-3">

                <Row>
                    <Col md="2">

                    </Col>
                    <Col md="5">

                        <Row>
                            <Col>
                                <h1 className="text-center text-black-100">Create Your Own Events For Free!</h1>
                            </Col>
                        </Row>

                        <Row>

                            <Col md="12">
                                <p className="text-black-100 text-center feature-text font-family-times">
                                    Post your own live or upcoming Events on Pondu, for free! You can either
                                    create a Private, Public or Exclusive event. Only your Friends can see your
                                    Private events. While your Public events are availble to be seen by all of
                                    Pondu’s users. Exclusive events are invite only, perfect for priavte parties
                                    and small
                                    gatherings!

                                </p>
                            </Col>
                        </Row>
                    </Col>

                    <Col md="5" className="phone-container"
                         style={{
                             backgroundImage: "url("+require('../../assets/img/landing/phone_5.png')+")"
                         }}
                    >

                    </Col>
                </Row>
            </div>

        </div>;
    }

}
export default FeatureSection;

